import React from "react";
import { Button, Flex, Image } from "@fluentui/react-northstar";
import { customLogout } from "../lib/authHelpers";
import { useTranslation } from "react-i18next";
import { Feedback } from "../elements/Feedback";
import { useUserData } from "../../utils/context/userContext";
import useScreenSize from "../../utils/hooks/useScreenSize";
import { HeaderProps } from "../../utils/types/common";
import TabletMenu from "./TabletMenu";

function Header({ isAdmin, inTeams, handleModeChange, themeString, mode, accessToken }: HeaderProps) {
  const { t } = useTranslation();
  const { setUserData } = useUserData();
  const { isTablet } = useScreenSize();

  return (
    <>
      {!isTablet ? (
        <Flex vAlign="center" space="between" styles={{ padding: "0.5em", height: "4em", marginLeft: "0.5em" }}>
          <Image
            id="cclogo"
            styles={{
              height: "100%",
              filter: themeString === "dark" || themeString === "contrast" ? "invert(100%)" : "",
              WebkitFilter: themeString === "dark" || themeString === "contrast" ? "invert(100%)" : "",
            }}
            src="./img/cccoachlogo.svg"
          />
          <Flex gap="gap.large">
            <Flex vAlign="center" gap="gap.small">
              <Feedback accessToken={accessToken} />
              {isAdmin && (
                <>
                  <Button content={t("Header.configureButton")} primary={mode === "configure"} onClick={() => handleModeChange("Configure")}></Button>
                  <Button content={t("Header.adminPanelButton")} primary={mode === "admin"} onClick={() => handleModeChange("Admin")}></Button>
                </>
              )}
              {!inTeams && <Button content="CyberCoach" primary={mode === "cybercoach"} onClick={() => handleModeChange("CyberCoach")}></Button>}
              <Button
                content={t("Header.profileButton")}
                primary={mode === "profile"}
                onClick={() => {
                  setUserData(null);
                  handleModeChange("Profile");
                }}
              ></Button>
            </Flex>
            {!inTeams && <Button primary content={t("Header.logoutButton")} onClick={() => customLogout()}></Button>}
          </Flex>
        </Flex>
      ) : (
        <TabletMenu
          isAdmin={isAdmin}
          inTeams={inTeams}
          handleModeChange={handleModeChange}
          themeString={themeString}
          mode={mode}
          accessToken={accessToken}
        />
      )}
    </>
  );
}

export default Header;
