import React from "react";
import {
  Flex,
  Popup,
  Chat,
  Avatar,
  InfoIcon,
  Text,
  CalendarIcon,
  EditIcon,
  LightningIcon,
  Table,
  Alert,
  ShorthandCollection,
  TableRowProps,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { TrainingsCompletedContentProps } from "../../../../utils/types/trainingsCompleted";
import useScreenSize from "../../../../utils/hooks/useScreenSize";

function TrainingPointsInfo({ training, learningObjectives }: TrainingsCompletedContentProps) {
  const { t } = useTranslation();
  return (
    <>
      {training.scorecardPositive && training.scorecardPositive.length > 0 && (
        <Flex column gap="gap.smaller" styles={{ marginTop: "1em" }}>
          <Text content={t("Profile.Trainings.questionsAced")} />
          {learningObjectives &&
            training.scorecardPositive.map((statement) => <Text content={learningObjectives[statement.scorecardid]} key={statement.scorecardid} />)}
        </Flex>
      )}
      {training.scorecardNegative && training.scorecardNegative.length > 0 && (
        <Flex column gap="gap.smaller">
          <Text content={t("Profile.Trainings.questionsFailed")} />
          {learningObjectives &&
            training.scorecardNegative.map((statement) => <Text content={learningObjectives[statement.scorecardid]} key={statement.scorecardid} />)}
        </Flex>
      )}
    </>
  );
}

export function TrainingDateContainer({ training, learningObjectives }: TrainingsCompletedContentProps) {
  return (
    <Flex gap="gap.smaller" styles={{ width: "100%" }}>
      <Text content={new Date(training.trainingdt).toLocaleDateString()} styles={{ marginRight: "0" }} />
      {(training.scorecardPositive || training.scorecardNegative) && learningObjectives && (
        <Popup
          on="hover"
          pointing
          mouseLeaveDelay={100}
          content={
            <Chat
              styles={{ paddingBottom: "1em" }}
              items={[
                {
                  gutter: <Avatar image="/img/bot-teams-color-purple.png" size="large" />,
                  key: "1",
                  message: (
                    <Chat.Message
                      author="CyberCoach"
                      content={<TrainingPointsInfo training={training} learningObjectives={learningObjectives} />}
                      timestamp={new Date(training.trainingdt).toLocaleDateString()}
                      styles={{ paddingBottom: "1em" }}
                    />
                  ),
                },
              ]}
            />
          }
          trigger={<InfoIcon outline alt="Click for details" styles={{ verticalAlign: "middle", cursor: "pointer" }} />}
        />
      )}
    </Flex>
  );
}

export function TrainingsTable({ tableRows }: { tableRows: ShorthandCollection<TableRowProps> | undefined }) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const trainingTableHeader = {
    items: [
      {
        key: "column1",
        content: (
          <>
            <Text content={t("Profile.Trainings.col1Header")} size="medium" weight="bold" />
            <EditIcon styles={{ color: "#6264A7", marginLeft: "6px" }} />
          </>
        ),
      },
      !isMobile && {
        key: "column2",
        content: (
          <>
            <Text content={t("Profile.Trainings.col2Header")} size="medium" weight="bold" />
            <LightningIcon styles={{ color: "#6264A7", marginLeft: "6px" }} />
          </>
        ),
      },
      {
        key: "column3",
        content: (
          <>
            <Text content={t("Profile.Trainings.col3Header")} size="medium" weight="bold" />
            <CalendarIcon styles={{ color: "#6264A7", marginLeft: "6px" }} />
          </>
        ),
      },
    ],
  };

  return (
    <Table
      key="trainingsTable"
      header={trainingTableHeader}
      aria-label="Trainings"
      styles={{ width: "100%", overflowY: "scroll", maxHeight: "90%" }}
      rows={tableRows}
    />
  );
}

export function NoCompletedTrainings() {
  const { t } = useTranslation();
  return <Alert content={t("Profile.Trainings.noCompletedTrainings")} />;
}
