import React, { useContext, useState, createContext } from "react";
import { CompanyConfigurationContextType, ICompanyConfiguration } from "../types/company";

export const CompanyConfigurationContext = createContext<CompanyConfigurationContextType | undefined>(undefined);

export const useCompanyConfigurationData = () => {
  const context = useContext(CompanyConfigurationContext);
  if (!context) {
    throw new Error("useCompanyConfiguration must be used within a CompanyConfigurationProvider");
  }
  return context;
};

const CompanyConfigurationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [companyConfiguration, setCompanyConfiguration] = useState<ICompanyConfiguration | undefined | null>(null);

  return (
    <CompanyConfigurationContext.Provider value={{ companyConfiguration, setCompanyConfiguration }}>{children}</CompanyConfigurationContext.Provider>
  );
};

export default CompanyConfigurationProvider;
