import React, { useState, useRef, useEffect } from "react";
import "../Profile.css";
import {
  Loader,
  Card,
  Flex,
  Header,
  Text,
  Button,
  PopupIcon,
  CloseIcon,
  Dialog,
  FilesTxtIcon,
  Alert,
  ErrorIcon,
  TextArea,
  TrashCanIcon,
  Input,
  ChevronEndMediumIcon,
  Divider,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { fetchFromBackend } from "../../lib/makeBackendCall";
import { ExportToExcel } from "../../lib/exportToExcel";

export function Assessments(props) {
  const { t } = useTranslation();
  const [assessments, setAssessments] = useState(props.assessments);
  const [openItem, setOpenItem] = useState();
  const accessToken = props.accessToken;

  const [excelData, setExcelData] = useState("");
  const excelLink = useRef(null);
  const [reportFail, setReportFail] = useState();

  function exportAssessment(assessmentsItem, index) {
    setReportFail();
    try {
      let reportData = [];
      for (const question of assessmentsItem.assessment.questionSet) {
        reportData.push({
          Question: question.question,
          Answer: question.userAnswer,
        });
      }
      reportData.push({ Question: assessmentsItem.assessmentName });
      setExcelData(reportData);
      excelLink.current = document.getElementById("assessmentExport-" + index);
    } catch (error) {
      console.log("Failed to generate report");
      setReportFail(true);
    }
  }
  async function deleteAssessment(assessmentId) {
    setAssessments();
    await fetchFromBackend(accessToken, "assessment/" + assessmentId, "DELETE");
    //get a new list of assessments
    const [data] = await fetchFromBackend(accessToken, "assessment");
    setAssessments(data);
  }

  useEffect(() => {
    if (excelData && excelLink.current) {
      excelLink.current.click();
      setExcelData("");
    }
  }, [excelData, excelLink]);

  return (
    <Card aria-roledescription="card avatar" elevated inverted className="profileCard" id="assessments">
      <Card.Header className="cardHeader">
        <Flex gap="gap.medium">
          <Flex column>
            <Header content={t("Profile.assessments.title")} weight="bold" className="cardHeadline" />
            <Text content={t("Profile.assessments.subTitle")} size="small" />
          </Flex>
        </Flex>
      </Card.Header>
      <Flex column gap="gap.small" vAlign="center" space="evenly" styles={{ maxHeight: "80%", width: "100%", overflowY: "scroll" }}>
        {assessments == null && <Loader label="Refreshing" />}
        {assessments && !assessments.error && (
          <>
            {reportFail && <Alert danger icon={<ErrorIcon />} content={"Failed to export data"} />}
            <Flex column gap="gap.small" vAlign="start" styles={{ height: "100%" }}>
              {assessments.map(function (assessmentsItem, i) {
                return (
                  <Flex vAlign="center" row="true" key={"assessmentsItem-" + i} styles={{ width: "100%" }} hAlign="start" space="between">
                    <Flex column>
                      <Text key={i + "_timeStamp"} size="small" weight="bold">
                        {new Date(assessmentsItem.assessmentdt).toLocaleString()}
                      </Text>
                      <Text key={i + "_headline"}>{assessmentsItem.assessmentName}</Text>
                    </Flex>
                    <Flex row="true">
                      <Button
                        outline="true"
                        icon={<PopupIcon />}
                        key={"assessmentsOpenBtn-" + i}
                        content={t("Profile.assessments.result")}
                        size="small"
                        onClick={() => setOpenItem({ ...assessmentsItem, index: i })}
                      />
                      <Button
                        outline="true"
                        icon={<FilesTxtIcon />}
                        key={"assessmentsExportBtn-" + i}
                        content={t("Profile.assessments.export")}
                        size="small"
                        onClick={() => exportAssessment(assessmentsItem, i)}
                      />
                      <Dialog
                        cancelButton="Cancel"
                        confirmButton="Delete"
                        content="This action cannot be reversed. Confirm delete"
                        header="Delete assessment"
                        onConfirm={() => deleteAssessment(assessmentsItem.assessmentId)}
                        trigger={
                          <Button styles={{ marginLeft: "0.5rem" }} size="small" icon={<TrashCanIcon />} iconOnly title={t("General.delete")} />
                        }
                      />
                      <ExportToExcel
                        id={"assessmentExport-" + i}
                        apiData={excelData}
                        innerRef={excelLink}
                        fileName={assessmentsItem.assessmentName + "_" + assessmentsItem.assessmentdt.replace(".", "")}
                      ></ExportToExcel>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </>
        )}
      </Flex>
      <Dialog
        styles={{ maxHeight: "80%", width: "60em", overflow: "scroll" }}
        open={openItem}
        onConfirm={() => setOpenItem()}
        closeOnOutsideClick={true}
        confirmButton="Close"
        content={
          <Flex column vAlign="center" gap="gap.medium" styles={{ height: "100%", width: "100%" }}>
            <Flex row="true" gap="gap.medium">
              <Flex row="true" gap="gap.smaller">
                <Text weight="bold" content={t("General.date") + ":"} />
                <Text content={new Date(openItem?.assessmentdt).toLocaleString()} />
              </Flex>
            </Flex>
            <Flex column gap="gap.large" styles={{ height: "95%", width: "100%", marginTop: "1rem" }}>
              {openItem?.assessment?.questionSet &&
                openItem.assessment.questionSet.map(function (question, index) {
                  const questionSplit = question.question.split("] ");
                  const questionQuestion = questionSplit.length > 1 ? questionSplit[1] : questionSplit[0];
                  return (
                    <>
                      <Flex column key={"question" + index} gap="gap.smaller">
                        <Flex row="true" gap="gap.smaller" styles={{ width: "100%" }}>
                          <Text weight="bold" size="large" key={"questionQuestionNumber" + index}>
                            {index + 1 + ")"}
                          </Text>
                          <TextArea fluid readOnly key={"questionQuestion" + index} value={questionQuestion}></TextArea>
                        </Flex>
                        <Flex row="true" gap="gap.smaller" vAlign="center" styles={{ width: "100%" }}>
                          <ChevronEndMediumIcon outline />
                          <Input fluid key={"questionAnswer" + index} value={question.userAnswer} readOnly />
                        </Flex>
                      </Flex>
                      <Divider />
                    </>
                  );
                })}
            </Flex>
          </Flex>
        }
        header={openItem?.assessmentName}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setOpenItem(),
        }}
      />
    </Card>
  );
}
