import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, Flex, Header, Text, Tooltip, Image, Button, ExcelColorIcon, ShorthandCollection, TableRowProps } from "@fluentui/react-northstar";
import "../../Profile.css";
import { useTranslation } from "react-i18next";
import { ExportToExcel } from "../../../lib/exportToExcel";
import { useUserData } from "../../../../utils/context/userContext";
import { NoCompletedTrainings, TrainingDateContainer, TrainingsTable } from "./TrainingsComponents";
import getTrainingScenarioType from "../../../../utils/helpers/getTrainingScenarioType";
import { TrainingsCompletedProps } from "../../../../utils/types/trainingsCompleted";
import useScreenSize from "../../../../utils/hooks/useScreenSize";

function Trainings({ learningObjectives, customQuizCompleted }: TrainingsCompletedProps) {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const { isMobile } = useScreenSize();
  const [tableRows, setTableRows] = useState<ShorthandCollection<TableRowProps> | undefined | null>(null);
  const [excelData, setExcelData] = useState<{ [x: string]: string | Date }[] | undefined>();
  const excelLink = useRef<HTMLButtonElement>(null);

  const completedTrainings = useMemo(() => {
    return userData?.trainingsCompleted ?? [];
  }, [userData?.trainingsCompleted]);

  const allCompletedTrainings = useMemo(() => {
    return customQuizCompleted ? [...completedTrainings, ...customQuizCompleted] : [...completedTrainings];
  }, [customQuizCompleted, completedTrainings]);

  const reportData = useMemo(() => {
    const trainings = allCompletedTrainings.map((training) => {
      const scenarioType = getTrainingScenarioType(training.scenarioType);
      return {
        [t("Profile.Trainings.col1Header")]: training.translation,
        [t("Profile.Trainings.col2Header")]: t("Profile.Trainings." + scenarioType),
        [t("Profile.Trainings.col3Header")]: new Date(training.trainingdt).toLocaleDateString(),
      };
    });

    const details = { [t("Profile.Trainings.col1Header")]: "Export date", [t("Profile.Trainings.col2Header")]: new Date() };

    return [...trainings, {}, details];
  }, [allCompletedTrainings, t]);

  const trainingRows = useMemo(() => {
    return allCompletedTrainings.map((training, index) => {
      if (!training) return;
      const scenarioType = getTrainingScenarioType(training.scenarioType);
      return {
        key: training.trainingdt,
        items: [
          {
            key: training.translation + index,
            content: <Text content={training.translation} />,
            truncateContent: true,
          },
          !isMobile && {
            key: scenarioType + index,
            content: t("Profile.Trainings." + scenarioType),
            truncateContent: true,
          },
          {
            key: training.trainingdt + index,
            content: <TrainingDateContainer training={training} learningObjectives={learningObjectives} />,
          },
        ],
      };
    });
  }, [allCompletedTrainings, isMobile, learningObjectives, t]);

  useEffect(() => {
    if (allCompletedTrainings?.length && trainingRows.length && reportData.length) {
      //make them in descending order (latest training on top)
      trainingRows.reverse();
      setTableRows(trainingRows);
      setExcelData(reportData);
    }
  }, [trainingRows, reportData, allCompletedTrainings?.length]);

  return (
    <Card
      aria-roledescription="card avatar"
      elevated
      inverted
      id="trainings"
      className="profileCard"
      styles={{ height: isMobile ? "fit-content" : "100%" }}
    >
      <Card.Header>
        <Header content={t("Profile.Trainings.title")} className="cardHeadline" />
      </Card.Header>
      {tableRows && tableRows?.length ? (
        <Flex column gap="gap.medium">
          <Flex hAlign="start" vAlign="center" gap="gap.smaller" space="between">
            <Flex hAlign="start" vAlign="center" gap="gap.smaller">
              <Text align="center" content={t("Profile.Trainings.subTitle")} size="medium" weight="bold" />
              <Text align="center" content={tableRows.length} size="medium" weight="bold" />
              <Image className="keyValueIcon" src="./img/trainings_completed.svg" fluid styles={{ maxWidth: "1.5em" }} />
            </Flex>
            {excelData && (
              <>
                <Tooltip
                  trigger={
                    <Button
                      icon={<ExcelColorIcon />}
                      content={t("Profile.Trainings.report")}
                      size="small"
                      onClick={() => excelLink?.current?.click()}
                    />
                  }
                  content={t("Profile.Trainings.reportTooltip")}
                />
                <ExportToExcel
                  innerRef={excelLink}
                  apiData={excelData}
                  fileName={t("Profile.Trainings.reportName") + "_" + new Date().toLocaleDateString()}
                />
              </>
            )}
          </Flex>
          <TrainingsTable tableRows={tableRows} />
        </Flex>
      ) : (
        <NoCompletedTrainings />
      )}
    </Card>
  );
}

export default Trainings;
