import React, { useState, useEffect, useContext } from "react";
import { Providers, Login, IProvider } from "@microsoft/mgt-react";
import { Text, Header, Image, Flex } from "@fluentui/react-northstar";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { msalConfig } from "../../lib/msalconfig";
import { ClientContext } from "../../App";
import { LoaderHelper } from "../LoaderHelper";
import { useLocation } from "react-router-dom";
import { CLIENT_TEAMS } from "../../lib/authHelpers";
import WebChatSignIn from "./WebChatSignIn";

export const SignInPage = () => {
  //render for now different loginpage if it is a webchat instance
  const isWebchat = process.env.REACT_APP_IS_WEBCHAT === "true";

  const [signInState, setSignInState] = useState<string | undefined>();

  const { clientInfo } = useContext(ClientContext) as any;

  //get location (URL path)
  const location = useLocation();

  const searchParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (target: URLSearchParams, prop: string | symbol) => {
      const method = target[prop as keyof URLSearchParams];

      // Check if the accessed property is a method of URLSearchParams
      if (typeof method === "function") {
        // Using the specific type of the method to ensure correct calling context
        return (...args: unknown[]) => (method as any).apply(target, args);
      }

      return method;
    },
  });

  useEffect(() => {
    //trigger login automatically if teamId is present as a query param
    searchParams.has("team_id") && !signInState && loginWithSlack();
  });

  /**
   * Creates a new session when login button for slack is clicked.
   */
  async function createSession(): Promise<string> {
    try {
      //if some sort of glitch start by deleting existing session
      await fetch("/session", { method: "DELETE" });
      const response = await fetch("/session?team_id=" + searchParams.get("team_id"));
      const data = await response.json();
      return data.nonce;
    } catch {
      //Failed to create session;
      throw new Error("Couldn't start login");
    }
  }
  /**
   * Logs user in with Slack
   */
  async function loginWithSlack() {
    try {
      setSignInState("signIn");
      const nonce = await createSession();
      //first set mode if the url contains that info
      const modeFromURL = location?.pathname?.startsWith("/") ? location.pathname.toLowerCase().substring(1) : undefined;
      const mode =
        modeFromURL === "profile" || modeFromURL === "admin" || modeFromURL === "configure" || modeFromURL === "cybercoach" ? modeFromURL : "";
      //clear location to not cause later issues
      window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);
      //store mode to session
      if (mode) {
        await fetch("/currentview/" + mode, {
          method: "POST",
        });
      }
      //now continue with login
      localStorage.setItem("ccclient", "Slack");
      window.location.assign(
        "https://slack.com/openid/connect/authorize?scope=openid%20email%20profile&response_type=code&nonce=" +
          nonce +
          "&redirect_uri=" +
          window.location.origin +
          "/&client_id=" +
          process.env.REACT_APP_SLACK_CLIENT_ID +
          (searchParams.has("team_id") ? "&team=" + searchParams.get("team_id") : "")
      );
    } catch (err) {
      console.log(err);
      setSignInState(undefined);
    }
  }

  async function signInWithMicrosoft(): Promise<void> {
    //initialize provider if not already. This might happen if both Slack and Microsoft are accepted as login methods.
    //In that case we are not able to initialize the provider before this when the login button is clicked.
    if (!Providers.globalProvider) {
      Providers.globalProvider = new Msal2Provider(msalConfig as any) as unknown as IProvider;
      localStorage.setItem("ccclient", "MSWeb");
    }
    setSignInState("signIn");

    if (Providers.globalProvider && typeof Providers.globalProvider.login === "function") {
      await Providers.globalProvider.login();
    } else {
      console.error("login function is not available on the globalProvider");
    }
    //trigger getting the accesstoken here for teams. Otherwise gets stuck at login screen
    if (clientInfo.client !== CLIENT_TEAMS) {
      await Providers.globalProvider.getAccessToken();
    }
  }

  return (
    <Flex column styles={{ height: "100%" }} hAlign="center">
      <Image
        id="cclogo"
        styles={{
          height: "5%",
          marginTop: "1rem",
          marginLeft: "1rem",
          marginRight: "auto",
        }}
        src="./img/cccoachlogo.svg"
      />
      {isWebchat ? (
        <WebChatSignIn />
      ) : (
        <>
          <Login hidden className="login" />
          {signInState === "signIn" && <LoaderHelper header="Authenticating" text="Signing you in to CyberCoach" showLoader={true} />}
          {!signInState && (
            <Flex column gap="gap.medium" styles={{ height: "90%", width: "100%" }} hAlign="center" vAlign="center">
              <Image src="/img/bot-teams-color-purple.png"></Image>
              <Header content="CyberCoach"></Header>
              <div
                style={{
                  width: "30%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Text align="center" content="Please click here to login. Welcome!"></Text>
              </div>
              <Flex column gap="gap.small">
                <input type="image" src="img/ms-symbollockup_signin_light.svg" name="login" onClick={() => signInWithMicrosoft()} alt="login" />
                {clientInfo?.client !== CLIENT_TEAMS && (
                  <div style={{ cursor: "pointer" }}>
                    <div
                      onClick={() => loginWithSlack()}
                      style={{
                        alignItems: "center",
                        color: "#fff",
                        backgroundColor: "#4A154B",
                        border: "0",
                        borderRadius: "48px",
                        display: "inline-flex",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "16px",
                        fontWeight: "600",
                        height: "48px",
                        justifyContent: "center",
                        textDecoration: "none",
                        width: "256px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ height: "20px", width: "20px", marginRight: "12px" }}
                        viewBox="0 0 122.8 122.8"
                      >
                        <path
                          d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                          fill="#e01e5a"
                        ></path>
                        <path
                          d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                          fill="#36c5f0"
                        ></path>
                        <path
                          d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                          fill="#2eb67d"
                        ></path>
                        <path
                          d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                          fill="#ecb22e"
                        ></path>
                      </svg>
                      Sign in with Slack
                    </div>
                  </div>
                )}
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};
