const getTrainingObjectives = (learningObjectives: Record<string, string>) => {
  const trainingToObjectives = new Map();

  for (const [objective, value] of Object.entries(learningObjectives)) {
    const training = objective.slice(0, -3);

    if (!trainingToObjectives.has(training)) {
      trainingToObjectives.set(training, []);
    }

    trainingToObjectives.get(training).push(value);
  }
  return trainingToObjectives;
};

export default getTrainingObjectives;
