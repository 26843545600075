import React from "react";
import { Loader, Flex, Header, Text } from "@fluentui/react-northstar";


export function LoaderHelper(props) {
  const header = props.header;
  const text = props.text;
  const showLoader = props.showLoader;
  return (
      <Flex column vAlign="center" hAlign="center" styles={{height:"90%"}}>
        {showLoader &&
          <pre className="fixed">
            <Loader size="largest" />
          </pre>
        }
        <Header content={header} align="center" />
        <Text content={text} />
      </Flex>
  );
}

