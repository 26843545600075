import { useMediaQuery } from "react-responsive";

const useScreenSize = () => {
  const isSmallMobile = useMediaQuery({ maxWidth: 375 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025, maxWidth: 1280 });
  const isLargeDesktop = useMediaQuery({ minWidth: 1281, maxWidth: 1440 });
  const isExtraLargeDesktop = useMediaQuery({ minWidth: 1441 });
  return { isSmallMobile, isMobile, isTablet, isDesktop, isLargeDesktop, isExtraLargeDesktop };
};

export default useScreenSize;
