import React, { useContext, useState, createContext } from "react";
import { CompanyContextType, ICompany } from "../types/company";

export const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

export const useCompanyData = () => {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};

const CompanyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [companyData, setCompanyData] = useState<ICompany | undefined | null>(null);

  return <CompanyContext.Provider value={{ companyData, setCompanyData }}>{children}</CompanyContext.Provider>;
};

export default CompanyProvider;
