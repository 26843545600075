import React, { useEffect, useState, useMemo, createContext } from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from "@fluentui/react-northstar";
import { Route, Routes } from "react-router-dom";
import Tab from "./Tab";
import "./App.css";
import { SignInPage } from "./views/SignIn/SignInPage";
import { LoaderHelper } from "./views/LoaderHelper";
import { Providers, ProviderState, SimpleProvider } from "@microsoft/mgt-react";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { app } from "@microsoft/teams-js";
import { msalConfig } from "./lib/msalconfig";
import { getSlackToken, getSlackuserInfo, checkIfSlackAdmin } from "./lib/slackAuth";
import jwtDecode from "jwt-decode";
import { EnterLicense } from "./welcomeModal/EnterLicense";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import { getWebchatUserProfile } from "./app_utils_temp";

import { customLogin, getAccessToken, getServerSideToken } from "./lib/microsoftAuth";
import { reactPlugin } from "./lib/appInsights";
import { AppInsightsErrorBoundary, AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useLoginState, customLogout, CLIENT_MICROSOFT_WEB, CLIENT_SLACK, CLIENT_TEAMS, CLIENT_WEBCHAT, getClient } from "./lib/authHelpers";
import UserProvider from "../utils/context/userContext";
import CompanyConfigurationProvider from "../utils/context/companyConfigurationContext";
import CompanyProvider from "../utils/context/companyContext";

//initialize translation
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const ClientContext = createContext({
  clientInfo: null,
});

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  //0=Loading, 1=Logged Out, 2=Logged IN
  const [loginState] = useLoginState();
  const [userInfo, setUserInfo] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [adminUser, setAdminUser] = useState(false);
  const [themeString, setThemeString] = useState("");
  const [theme, setTheme] = useState(teamsTheme);
  const [backgroundColor, _setBackGroundColor] = useState("");
  const [tenantValid, setTenantValid] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [backendAccess, setBackendAccess] = useState(true);

  //track the loginstate with this one regardless of provider
  const [compositeLoginState, setCompositeLoginState] = useState(loginState);
  useEffect(() => {
    setCompositeLoginState(loginState);
  }, [loginState]);

  const [clientInfo, setClientInfo] = useState({
    client: null,
  });

  useEffect(() => {
    async function checkAndSetClient() {
      const client = await getClient();
      setClientInfo((clientInfo) => ({ ...clientInfo, client: client }));
    }
    !clientInfo.client && checkAndSetClient();
  }, [clientInfo.client]);

  useEffect(() => {
    async function initializeProvider() {
      if (clientInfo.client === CLIENT_TEAMS) {
        try {
          if (!app.isInitialized()) {
            await app.initialize();
          }
          app.notifyAppLoaded();
          const context = await app.getContext();
          setThemeString(context.app.theme);
          app.registerOnThemeChangeHandler(function (theme) {
            setThemeString(theme);
          });

          Providers.globalProvider = new SimpleProvider(getServerSideToken, customLogin, customLogout);

          await Providers.globalProvider.getAccessToken();

          //if we are here we are authenticated
          Providers.globalProvider.setState(ProviderState.SignedIn);
          app.notifySuccess();
        } catch (error) {
          //consents might be missing, login flow must be initialized
          app.notifyExpectedFailure({ reason: app.FailedReason.AuthFailed, message: "Consent needed" });
          //setting state to signedout will render login page
          Providers.globalProvider.setState(ProviderState.SignedOut);
        }
      } else if (clientInfo.client !== CLIENT_TEAMS) {
        setBackGroundColor("#eeeeee");
        if (clientInfo.client === "N/A") {
          //just mark as as logged out. Need to pass through login page
          setCompositeLoginState(ProviderState.SignedOut);
        }
        //Microsoft authentication . We can create the provider also try to login
        else if (clientInfo.client === CLIENT_MICROSOFT_WEB) {
          try {
            Providers.globalProvider = new Msal2Provider(msalConfig);
          } catch {
            Providers.globalProvider.setState(ProviderState.SignedOut);
            localStorage.removeItem("ccclient");
            setClientInfo((clientInfo) => (clientInfo.client = "N/A"));
          }
        }
      }
    }
    clientInfo.client && !accessToken && initializeProvider();
  }, [clientInfo.client, accessToken]);

  // nothing to do with logging in
  useEffect(() => {
    switch (themeString) {
      case "dark":
        setBackGroundColor("#2D2C2C");
        setTheme(teamsDarkTheme);
        break;
      case "contrast":
        setBackGroundColor("#2D2C2C");
        setTheme(teamsHighContrastTheme);
        break;
      default:
        setBackGroundColor("#eeeeee");
        setTheme(teamsTheme);
    }
  }, [themeString]);

  // nothing to do with logging in
  const setBackGroundColor = (color) => {
    document.body.style.background = color;
    _setBackGroundColor(color);
  };

  useEffect(() => {
    async function getProfileInfo() {
      try {
        const me = await Providers.globalProvider.graph.api("/me").get();
        setUserInfo(me);
        try {
          const photo = await Providers.globalProvider.graph.api("/me/photo/$value").get();
          setUserInfo({ ...me, userPicture: URL.createObjectURL(photo) });
        } catch {
          console.log("Failed to fetch user picture. Showing initials instead");
        }
        const scopeOptions = { scopes: [process.env.REACT_APP_IDENTIFIER + "/access_as_user"] };
        const token = await getAccessToken(clientInfo.client === CLIENT_TEAMS, scopeOptions);
        const decodedToken = jwtDecode(token);
        setTenant(decodedToken.tid);
        const validTenant = await validateTenant(decodedToken.tid);
        setTenantValid(validTenant);
        setAccessToken(token);
        setClientInfo((clientInfo) => (clientInfo.accessToken = token));
        if (validTenant) {
          if (decodedToken?.roles?.includes("CyberCoachAdmin")) {
            setAdminUser(true);
          }
        } else {
          throw new Error("Tenant is not valid");
        }
      } catch (err) {
        //Failed to get user information or validate the user organization
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }
    async function getSlackProfileInfo() {
      try {
        const me = await getSlackuserInfo(accessToken);
        setUserInfo(me);
        setTenant(me.tid);
        const validTenant = await validateTenant(me.tid);
        setTenantValid(validTenant);
        const isAdmin = await checkIfSlackAdmin(accessToken);
        setAdminUser(isAdmin);
        setCompositeLoginState(ProviderState.SignedIn);
      } catch (err) {
        //Failed to get user information or validate the user organization
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }

    async function getWebchatProfileInfo() {
      try {
        const profile = await getWebchatUserProfile();
        setUserInfo(profile);
        setTenant(profile.tid);
        const isValidTenant = await validateTenant(profile.tid);
        setTenantValid(isValidTenant);
        setAdminUser(false);
        setCompositeLoginState(ProviderState.SignedIn);
        // note - this is probably not right, is this for slack/whatever?
        setAccessToken("webchat-access-token");
      } catch (err) {
        console.log(err);
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }

    if (clientInfo.client === CLIENT_WEBCHAT && tenantValid === null) {
      getWebchatProfileInfo();
    } else if (compositeLoginState === ProviderState.Loading && clientInfo.client === CLIENT_SLACK && accessToken && tenantValid === null) {
      getSlackProfileInfo();
    } else if (compositeLoginState === ProviderState.SignedIn && !accessToken && tenantValid === null) {
      getProfileInfo();
    }
  }, [compositeLoginState, accessToken, clientInfo.client, tenantValid]);

  async function validateTenant(tenantId) {
    try {
      const tenantApproved = await fetch("/tenant/validate", {
        headers: {
          externalId: tenantId,
        },
      });
      if (tenantApproved.ok) {
        const validateData = await tenantApproved.json();
        return validateData.valid;
      } else if (tenantApproved.status === 500) {
        setBackendAccess(false);
        throw new Error("Connection to database couldn't be extablished");
      } else {
        throw new Error("User organization doesn't have a valid license");
      }
    } catch (err) {
      setCompositeLoginState(ProviderState.SignedOut);
      return false;
    }
  }

  useEffect(() => {
    async function getToken() {
      const queryParams = new URLSearchParams(window.location.search);
      //if we have a team_id it means we should start again from login
      if (!queryParams.get("team_id")) {
        try {
          setCompositeLoginState(ProviderState.Loading);
          const slackToken = await getSlackToken(queryParams.get("code"));
          setAccessToken(slackToken.token);
          setClientInfo((clientInfo) => ({ ...clientInfo, accessToken: slackToken.token, queryTeamId: slackToken.queryTeamId }));
        } catch (err) {
          //Failed to authenticate user. We need new Slack Token
          setCompositeLoginState(ProviderState.SignedOut);
        }
      } else {
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }
    //get slack token
    clientInfo?.client === CLIENT_SLACK && !accessToken && getToken();
  }, [clientInfo.client, accessToken]);

  const clientContext = useMemo(() => ({ clientInfo, setClientInfo }), [clientInfo]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => (
          <LoaderHelper
            header="Something went wrong:("
            text="We apologize for this. Try refreshing the page and if that doesn't help reach out to the CyberCoach Team"
          />
        )}
        appInsights={reactPlugin}
      >
        <ClientContext.Provider value={clientContext}>
          <Provider theme={theme} styles={{ backgroundColor: backgroundColor, width: "100%", height: "inherit" }}>
            {tenantValid === false && backendAccess && (
              <>
                <EnterLicense tenant={tenant} accessToken={accessToken} teamName={userInfo.teamName} queryTeamId={clientInfo.queryTeamId} />
                <LoaderHelper header="Organization not recognized" text="Your organization is not recognized by CyberCoach" />
              </>
            )}
            {!backendAccess && <LoaderHelper header="Server error" text="Connection to server couldn't be established" />}
            {(compositeLoginState === ProviderState.SignedOut || compositeLoginState === false) && backendAccess && clientInfo?.client && (
              <SignInPage />
            )}
            {(compositeLoginState === ProviderState.Loading || !ProviderState || !accessToken || !userInfo || !tenantValid) &&
              compositeLoginState !== ProviderState.SignedOut && (
                <LoaderHelper header="Authenticating" text="Signing you in to CyberCoach" showLoader={true} />
              )}
            {compositeLoginState === ProviderState.SignedIn && accessToken && userInfo && tenantValid && (
              <Routes>
                <Route
                  path="*"
                  element={
                    <CompanyProvider>
                      <UserProvider>
                        <CompanyConfigurationProvider>
                          <Tab
                            accessToken={accessToken}
                            providerAdmin={adminUser}
                            inTeams={clientInfo.client === CLIENT_TEAMS}
                            userInfo={userInfo}
                            themeString={themeString}
                          />
                        </CompanyConfigurationProvider>
                      </UserProvider>
                    </CompanyProvider>
                  }
                />
              </Routes>
            )}
          </Provider>
        </ClientContext.Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}
