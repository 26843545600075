import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Text, Image, Tooltip, Flex } from "@fluentui/react-northstar";
import { BadgeElementProps } from "../../../../utils/types/profile";
import getAllTrainingDetails from "../../../../utils/helpers/getAllTrainingDetails";
import { TrainingType } from "../../../../utils/types/common";
import { useCompanyData } from "../../../../utils/context/companyContext";

function BadgeElement({ trainings, setTrainingToLaunch }: BadgeElementProps) {
  const { t } = useTranslation();
  const { companyData } = useCompanyData();
  const isTrial = process.env.REACT_APP_ENV_TYPE === "trial";
  const longLearningPath = trainings && trainings?.length > 30;
  const gridLength = trainings && trainings?.length > 15 ? 4 : 3;

  const badgeStyle = {
    margin: "auto",
    padding: "0.2em",
    cursor: "pointer",
  };
  const badgeStyleMandatory = {
    margin: "auto",
    padding: "0.2em",
    cursor: "pointer",
    filter:
      "drop-shadow(-1px -1px 0px #cc33ff)" +
      "drop-shadow(2px -1px 0px #cc33ff)" +
      "drop-shadow(2px 2px 0px #cc33ff)" +
      "drop-shadow(-1px 2px 0px #cc33ff)",
  };
  const notUnlockedbadgeStyle = {
    margin: "auto",
    padding: "0.2em",
    filter: "grayscale(100)",
    opacity: "0.3",
    cursor: "pointer",
  };
  const notUnlockedbadgeStyleMandatory = {
    margin: "auto",
    padding: "0.2em",
    opacity: "0.3",
    cursor: "pointer",
    filter:
      "grayscale(100)" +
      "drop-shadow(-1px -1px 0px #cc33ff)" +
      "drop-shadow(2px -1px 0px #cc33ff)" +
      "drop-shadow(2px 2px 0px #cc33ff)" +
      "drop-shadow(-1px 2px 0px #cc33ff)",
  };

  return (
    <Grid
      id="badgeElement"
      styles={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        justifyContent: "center",
        overflow: "scroll",
      }}
      columns={longLearningPath ? 5 : gridLength}
    >
      {trainings?.map((training) => {
        const completedBadge = training?.mandatory ? badgeStyleMandatory : badgeStyle;
        const notUnlocked = training?.mandatory ? notUnlockedbadgeStyleMandatory : notUnlockedbadgeStyle;
        const { trainingName, trainingDialog, imageUrl, trainingType } = getAllTrainingDetails(training);
        const trainingToLaunchContent = {
          shortname: trainingDialog,
          name: trainingName,
          type: trainingType,
        };
        return (
          <Tooltip
            key={trainingName}
            trigger={
              <Image
                src={imageUrl}
                fluid
                styles={training?.completed ? completedBadge : notUnlocked}
                onClick={() => !isTrial && setTrainingToLaunch(trainingToLaunchContent)}
              />
            }
            content={
              <Flex column>
                <Text content={trainingName} />
                <Text
                  content={training?.completedOn ? t("Profile.Badges.completedOn") + ": " + new Date(training?.completedOn).toLocaleDateString() : ""}
                />
                {trainingType === TrainingType.CUSTOM && (
                  <Text content={t("Profile.CustomContent.subTitle", { companyName: companyData?.companyName })} />
                )}
              </Flex>
            }
          />
        );
      })}
    </Grid>
  );
}

export default BadgeElement;
