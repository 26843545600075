import { Dialog, Flex, Text, Image } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { SetTrainingToLaunchProps } from "../../utils/types/profile";
import { TrainingType } from "../../utils/types/common";

function StartTrainingDialog({ trainingToLaunch, handleModeChange, setTrainingToLaunch }: SetTrainingToLaunchProps) {
  const { t } = useTranslation();
  const openDialog = !!trainingToLaunch?.shortname && trainingToLaunch?.shortname !== "";
  const imageUrl =
    trainingToLaunch?.type === TrainingType.CUSTOM
      ? "/img/custom_badge.svg"
      : `${process.env.REACT_APP_IMAGE_BASE_URL}/badge/${trainingToLaunch?.shortname}.svg`;
  const modeChangeContent = `launch ${trainingToLaunch?.type} ${trainingToLaunch?.shortname}`;
  return (
    <Dialog
      cancelButton={t("General.Cancel")}
      confirmButton={t("Profile.Badges.startTraining")}
      content={
        <Flex vAlign="center" styles={{ width: "100%" }}>
          <Text styles={{ width: "80%" }} content={t("Profile.Badges.launchWarningMessage", { trainingName: trainingToLaunch?.name })} />
          <Image styles={{ width: "20%", height: "auto" }} src={imageUrl} />
        </Flex>
      }
      header={t("Profile.Badges.launch") + ": " + trainingToLaunch?.name}
      onConfirm={() => {
        handleModeChange("CyberCoach", modeChangeContent);
        setTrainingToLaunch(undefined);
      }}
      open={openDialog}
      onCancel={() => setTrainingToLaunch(undefined)}
    />
  );
}

export default StartTrainingDialog;
