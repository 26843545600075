import React, { useState } from "react";
import { Flex, Header, Alert, ExclamationTriangleIcon, Button, Text, Input, Image, AcceptIcon, Loader } from "@fluentui/react-northstar";
import { Link } from "@fluentui/react";
import useScreenSize from "../../../utils/hooks/useScreenSize";
import validateEmail from "../../../utils/helpers/validateEmail";
import { CLIENT_WEBCHAT } from "../../lib/authHelpers";

function WebChatSignIn() {
  const { isMobile } = useScreenSize();
  const [email, setEmail] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [reSendCode, setReSendCode] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoginError, setShowLoginError] = useState<boolean>(false);

  const loginUrl = "/account/login";
  const xsrfCookie = document.cookie
    .split(";")
    .map((cookieValue) => cookieValue.split("=").map((cookiePart) => cookiePart.trim()))
    .find((elem) => elem[0] === "XSRF-TOKEN");

  async function handleWebLogin(email: string, verificationCode: string | undefined) {
    if (!validateEmail(email) || verificationCode?.length !== 6 || xsrfCookie === undefined) {
      setIsLoading(false);
      setShowLoginError(true);
      return;
    }

    const xsrfCookieValue = xsrfCookie[1];
    const payload = {
      __RequestVerificationToken: xsrfCookieValue,
      email: email,
      code: verificationCode,
    };

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        redirect: "manual",
      });
      const body = await response.json();
      console.log(body);

      if (body?.status === "logged_in") {
        localStorage.setItem("ccclient", CLIENT_WEBCHAT);
        window.location.href = body.redirect_to;
      } else {
        setIsLoading(false);
        setShowLoginError(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      setShowLoginError(true);
    }
  }

  async function sendVerificationCode() {
    if (validateEmail(email)) {
      if (xsrfCookie === undefined) return;

      const xsrfCookieValue = xsrfCookie[1];

      const payload = {
        __RequestVerificationToken: xsrfCookieValue,
        email: email,
      };

      await fetch(loginUrl, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(payload),
      });

      setCodeSent(true);
    } else {
      setIsValidEmail(false);
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      sendVerificationCode();
    }
  };

  const handleReSendClick = () => {
    setReSendCode(true);
    setTimeout(() => {
      setReSendCode(false);
    }, 3000);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    handleWebLogin(email, verificationCode);
  };

  return isLoading ? (
    <Flex styles={{ height: "100%", width: "100%" }} hAlign="center" vAlign="center">
      <Loader />
    </Flex>
  ) : (
    <Flex column gap="gap.medium" styles={{ height: "100%", width: "100%", padding: "0 24px" }} hAlign="center" vAlign="center">
      <Image src="/img/bot-teams-color-purple.png"></Image>
      {!codeSent && (
        <>
          <Header content="CyberCoach"></Header>
          <Text align="center" content="Provide your CyberCoach linked email and a verification code for login will be sent."></Text>
          <Flex hAlign="center" gap="gap.small" style={{ flexDirection: isMobile ? "column" : "row", width: "100%" }}>
            <Flex column styles={{ width: isMobile ? "100%" : "20rem", marginBottom: "16px" }}>
              <Input
                type="email"
                onKeyDown={handleKeyDown}
                fluid
                placeholder="Enter your email here"
                value={email}
                onChange={(e, data) => setEmail(data?.value ?? "")}
                error={!isValidEmail}
                aria-invalid={!isValidEmail}
                styles={{ marginBottom: !isValidEmail ? "8px" : "0" }}
              />
              {!isValidEmail && <Alert warning content={"Please provide a valid email address"} icon={<ExclamationTriangleIcon />} />}
            </Flex>
            <Button fluid primary style={{ width: isMobile ? "100%" : "fit-content" }} content={"Send code"} onClick={() => sendVerificationCode()} />
          </Flex>
        </>
      )}
      {codeSent && (
        <Flex column gap="gap.medium" hAlign="center">
          <Text content="Enter 6-digit code" size="large" />
          <Text align="center" content={`We sent verification code to ${email}`} />
          <Flex hAlign="center" gap="gap.small" styles={{ width: "100%", flexWrap: "wrap" }}>
            <Flex gap="gap.small" style={{ width: isMobile ? "100%" : "auto", marginBottom: isMobile ? "16px" : "0" }}>
              <Input
                value={verificationCode}
                onChange={(e, data) => setVerificationCode(data?.value ?? "")}
                maxLength={6}
                placeholder="Enter code"
                fluid
              />
            </Flex>
            <Button
              content="Log in"
              primary
              fluid
              style={{ width: isMobile ? "100%" : "fit-content" }}
              onClick={handleSubmit}
              disabled={verificationCode?.length !== 6}
            />
          </Flex>
          {showLoginError && (
            <Alert
              danger
              style={{ maxWidth: isMobile ? "100%" : "60%" }}
              content={"We couldn't log you in, please make sure you used correct verification code"}
              icon={<ExclamationTriangleIcon />}
            />
          )}
          {reSendCode && <Alert success content="Code was sent to your email" icon={<AcceptIcon />} />}
          <Link
            onClick={() => {
              sendVerificationCode();
              handleReSendClick();
            }}
          >
            Send verification code again
          </Link>
        </Flex>
      )}
    </Flex>
  );
}

export default WebChatSignIn;
