import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormTextArea,
  Dialog,
  Form,
  FormButton,
  Flex,
  CloseIcon,
  Text,
  FormCheckbox,
  FormSlider,
  Loader,
  Label,
  NotepadPersonIcon,
  RobotIcon,
  FormRadioGroup,
  FormDatepicker,
  FormDropdown,
} from "@fluentui/react-northstar";
import "../App.css";
import { fetchFromBackend } from "../lib/makeBackendCall";
import { useTranslation } from "react-i18next";

const timeDropDown = [
  "00:00-01.00",
  "01:00-02.00",
  "02:00-03.00",
  "03:00-04.00",
  "04:00-05.00",
  "05:00-06.00",
  "06:00-07.00",
  "07:00-08.00",
  "08:00-09.00",
  "09:00-10.00",
  "10:00-11.00",
  "11:00-12.00",
  "12:00-13.00",
  "13:00-14.00",
  "14:00-15.00",
  "15:00-16.00",
  "16:00-17.00",
  "17:00-18.00",
  "18:00-19.00",
  "19:00-20.00",
  "20:00-21.00",
  "21:00-22.00",
  "22:00-23.00",
  "23:00-24.00",
];

Feedback.propTypes = {
  accessToken: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export function Feedback(props) {
  const { t } = useTranslation();

  const [mode, setMode] = useState("Feedback");
  const [feedbackDialog, setFeedbackDialog] = useState();

  const [feedbackContent, setFeedbackContent] = useState({
    increaseUnderstanding: {
      answer: "N/A",
      reason: "",
    },
    enjoyable: {
      answer: "N/A",
      reason: "",
    },
    likelyToRecommend: 5,
    newFeaturesTopics: "",
  });
  const [bugContent, setBugContent] = useState({
    bugLocation: "",
    errorDesc: "",
    bugDate: "",
    bugTime: "",
  });

  const [submitting, setSubmitting] = useState();
  const [submitted, setSubmitted] = useState();

  const thankyouMessage = mode === "Feedback" ? t("Feedback.thankYou1") : t("BugReport.thankYou1");

  async function submitFeedback(content) {
    setSubmitting(true);
    const feedbackItem = {
      conversationId: "N/A",
      scenarioName: "",
      scenarioVersion: "",
      scenarioRating: "",
      feedbackCategory: mode === "BugReport" ? "BugReport " : "General",
      feedback: content,
    };
    try {
      await fetchFromBackend(props.accessToken, "feedback", "POST", feedbackItem);
      setSubmitted(true);
    } catch (err) {
      console.error("Failed to give feedback or report a bug");
      setSubmitted(false);
    }
    setSubmitting(false);
  }

  return (
    <>
      <Button styles={{ width: props.width }} onClick={() => setFeedbackDialog(true)} content={t("Feedback.header")} tinted />
      <Dialog
        styles={{ maxHeight: "90%", width: "60em", overflow: "scroll" }}
        open={feedbackDialog}
        closeOnOutsideClick={true}
        content={
          <Flex column vAlign="center" hAlign="center" gap="gap.medium" styles={{ height: "100%", width: "100%", marginTop: "1rem" }}>
            {!submitting && !submitted && (
              <Flex gap="gap.small">
                <Button
                  size="small"
                  icon={<NotepadPersonIcon />}
                  primary={mode === "Feedback"}
                  onClick={() => setMode("Feedback")}
                  content={t("Feedback.header")}
                />
                <Button
                  size="small"
                  icon={<RobotIcon />}
                  primary={mode === "BugReport"}
                  onClick={() => setMode("BugReport")}
                  content={t("BugReport.header")}
                />
              </Flex>
            )}
            {mode === "Feedback" && (
              <>
                {submitted && (
                  <Flex column gap="gap.medium" styles={{ width: "100%" }} hAlign="start">
                    <Text content={t("Feedback.thankYou2")} />
                    <Text content={t("Feedback.thankYou3")} />
                  </Flex>
                )}
                {submitted === false && (
                  <Flex column gap="gap.medium" styles={{ width: "100%" }} hAlign="start">
                    <Text weight="bold" content={"Something went wrong :("} />
                    <Text content={"Not being able to give feedback is indeed very bad."} />
                    <Text content={"We are monitoring the service constantly and this error will for sure catch our attention."} />
                    <Text content={"You can also reach out to the CyberCoach team using some other challenge while we are fixing this. Apologies."} />
                  </Flex>
                )}
                {submitting && <Loader label={t("Feedback.submitting")} />}
                {!submitting && !submitted && (
                  <Form
                    className="feedbackForm"
                    styles={{ gap: "1.5rem", marginTop: "1rem" }}
                    onSubmit={() => {
                      submitFeedback(feedbackContent);
                    }}
                  >
                    <Flex column gap="gap.smaller">
                      <Text weight="bold" content={t("Feedback.increaseUnderstandingQuestion")} />
                      <Flex row="true">
                        <FormCheckbox
                          labelPosition="start"
                          label={t("General.Yes")}
                          id="IncreaseUnderstandingYes"
                          checked={feedbackContent.increaseUnderstanding.answer === "Yes"}
                          onClick={() =>
                            setFeedbackContent({
                              ...feedbackContent,
                              increaseUnderstanding: { ...feedbackContent.increaseUnderstanding, answer: "Yes" },
                            })
                          }
                        />
                        <FormCheckbox
                          labelPosition="start"
                          label={t("General.No")}
                          id="IncreaseUnderstandingNo"
                          checked={feedbackContent.increaseUnderstanding.answer === "No"}
                          onClick={() =>
                            setFeedbackContent({
                              ...feedbackContent,
                              increaseUnderstanding: { ...feedbackContent.increaseUnderstanding, answer: "No" },
                            })
                          }
                        />
                      </Flex>
                      {feedbackContent.increaseUnderstanding.answer !== "N/A" && (
                        <FormTextArea
                          onInput={(e) =>
                            setFeedbackContent({
                              ...feedbackContent,
                              increaseUnderstanding: { ...feedbackContent.increaseUnderstanding, reason: e.target.value },
                            })
                          }
                          fluid
                          label={t("Feedback.whyQuestion")}
                          id="whyIncrease"
                          rows={3}
                        />
                      )}
                    </Flex>
                    <Flex column gap="gap.smaller">
                      <Text weight="bold" content={t("Feedback.enjoyableQuestion")} />
                      <Flex row="true">
                        <FormCheckbox
                          labelPosition="start"
                          label={t("General.Yes")}
                          id="EnjoyableYes"
                          checked={feedbackContent.enjoyable.answer === "Yes"}
                          onClick={() => setFeedbackContent({ ...feedbackContent, enjoyable: { ...feedbackContent.enjoyable, answer: "Yes" } })}
                        />
                        <FormCheckbox
                          labelPosition="start"
                          label={t("General.No")}
                          id="EnjoyableeNo"
                          checked={feedbackContent.enjoyable.answer === "No"}
                          onClick={() => setFeedbackContent({ ...feedbackContent, enjoyable: { ...feedbackContent.enjoyable, answer: "No" } })}
                        />
                      </Flex>
                      {feedbackContent.enjoyable.answer !== "N/A" && (
                        <FormTextArea
                          onInput={(e) =>
                            setFeedbackContent({ ...feedbackContent, enjoyable: { ...feedbackContent.enjoyable, reason: e.target.value } })
                          }
                          fluid
                          label={t("Feedback.whyQuestion")}
                          id="whyIncrease"
                          rows={3}
                        />
                      )}
                    </Flex>
                    <Flex column gap="gap.medium">
                      <Text weight="bold" content={t("Feedback.likelyRecommendQuestion")} />
                      <Flex row="true" gap="gap.small" vAlign="start" styles={{ marginTop: "0.5rem" }}>
                        <Text weight="bold" size="small" content={t("Feedback.recommendNo")} />
                        <Flex column hAlign="center">
                          <FormSlider
                            value={feedbackContent.likelyToRecommend}
                            onChange={(e, data) => setFeedbackContent({ ...feedbackContent, likelyToRecommend: data.value })}
                            min={0}
                            max={10}
                            step={1}
                            required
                          />
                          <Label content={feedbackContent.likelyToRecommend} />
                        </Flex>
                        <Text weight="bold" size="small" content={t("Feedback.recommendVery")} />
                      </Flex>
                    </Flex>
                    <Flex column gap="gap.smaller">
                      <Text weight="bold" content={t("Feedback.topicsFeaturesQuestion")} />
                      <FormTextArea
                        value={feedbackContent.newFeaturesTopics}
                        onInput={(e) => setFeedbackContent({ ...feedbackContent, newFeaturesTopics: e.target.value })}
                        fluid
                        id="whatFeaturesTopics"
                        rows={5}
                      />
                    </Flex>
                    <FormButton content={t("Feedback.submitButton")} primary />
                  </Form>
                )}
              </>
            )}
            {mode === "BugReport" && (
              <>
                {submitted && (
                  <Flex column gap="gap.medium" styles={{ width: "100%" }} hAlign="start">
                    <Text content={t("BugReport.thankYou2")} />
                  </Flex>
                )}
                {submitted === false && (
                  <Flex column gap="gap.medium" styles={{ width: "100%" }} hAlign="start">
                    <Text weight="bold" content={"Something went wrong :("} />
                    <Text content={"If reporting bugs is a bug it is indeed very bad."} />
                    <Text content={"We are monitoring the service constantly and this error will for sure catch our attention."} />
                    <Text content={"You can also reach out to the CyberCoach team using some other challenge while we are fixing this. Apologies."} />
                  </Flex>
                )}
                {submitting && <Loader label={t("Feedback.submitting")} />}
                {!submitting && !submitted && (
                  <Form
                    className="bugReportForm"
                    styles={{ gap: "1.5rem", marginTop: "1rem" }}
                    onSubmit={() => {
                      submitFeedback(bugContent);
                    }}
                  >
                    <Text content={t("BugReport.bugMessage")} />
                    <Flex column gap="gap.smaller">
                      <Text weight="bold" content={t("BugReport.bugLocation")} />
                      <FormRadioGroup
                        checkedValue={bugContent.bugLocation}
                        items={[
                          {
                            name: "bot",
                            key: "bot",
                            label: t("BugReport.botLabel"),
                            value: "bot",
                            checked: bugContent.bugLocation === "bot",
                            onClick: () => setBugContent({ ...bugContent, bugLocation: "bot" }),
                          },
                          {
                            name: "profile",
                            key: "profile",
                            label: t("Header.profileButton"),
                            value: "profile",
                            checked: bugContent.bugLocation === "profile",
                            onClick: () => setBugContent({ ...bugContent, bugLocation: "profile" }),
                          },
                          {
                            name: "dashmin",
                            key: "dashmin",
                            label: t("Header.adminModeButton"),
                            value: "dashmin",
                            checked: bugContent.bugLocation === "dashmin",
                            onClick: () => setBugContent({ ...bugContent, bugLocation: "dashmin" }),
                          },
                        ]}
                      />
                    </Flex>
                    {bugContent.bugLocation && (
                      <>
                        <Flex column gap="gap.smaller">
                          <Text weight="bold" content={t("BugReport.describeError")} />
                          <Text size="small" content={t("BugReport.describeInfo")} />
                          <FormTextArea
                            required
                            value={bugContent.errorDesc}
                            onInput={(e) => setBugContent({ ...bugContent, errorDesc: e.target.value })}
                            fluid
                            id="errorDesc"
                            rows={5}
                          />
                        </Flex>
                        <Flex column gap="gap.smaller">
                          <Text weight="bold" content={t("BugReport.whenError")} />
                          <Text size="small" content={t("BugReport.whenInfo")} />
                          <Flex row="true" gap="gap.small">
                            <FormDatepicker
                              label={t("BugReport.dateSelect")}
                              maxDate={new Date()}
                              placeholder={t("BugReport.dateSelect")}
                              selectedDate={bugContent.bugDate}
                              onDateChange={(e, v) => setBugContent({ ...bugContent, bugDate: v.value })}
                            />
                            <FormDropdown
                              fluid
                              label={t("BugReport.timeSelect")}
                              items={timeDropDown}
                              getA11ySelectionMessage={{
                                onAdd: (item) => setBugContent({ ...bugContent, bugTime: item }),
                              }}
                            />
                          </Flex>
                        </Flex>
                      </>
                    )}
                    <FormButton content={t("Feedback.submitButton")} primary />
                  </Form>
                )}
              </>
            )}
          </Flex>
        }
        header={submitted ? thankyouMessage : t("Feedback.header")}
        headerAction={{
          icon: <CloseIcon />,
          title: t("General.Close"),
          onClick: () => setFeedbackDialog() + setSubmitted(),
        }}
      />
    </>
  );
}
