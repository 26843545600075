import React from "react";
import { Flex, Text, Divider } from "@fluentui/react-northstar";
import useScreenSize from "../../../../utils/hooks/useScreenSize";
import { TrainingStatsProps } from "../../../../utils/types/progress";
import { useTranslation } from "react-i18next";

function TrainingsStats({ learningPathInfo, customTrainings }: TrainingStatsProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const customCompleted = customTrainings?.filter((training) => training.completed);

  return (
    <Flex
      column={!isMobile}
      gap="gap.medium"
      styles={{ width: isMobile ? "100%" : "30%", height: isMobile ? "20%" : "100%" }}
      hAlign="start"
      vAlign="center"
    >
      <Flex hAlign="end" vAlign="center" styles={{ width: "100%" }}>
        <Flex column hAlign="center" styles={{ width: "35%" }}>
          <Text size="larger" weight="bold" content={learningPathInfo.mandatoryCompleted} />
          <Divider color="black" important styles={{ width: "50%" }} />
          <Text size="larger" weight="bold" content={learningPathInfo.mandatoryTrainings} />
        </Flex>
        <Text align="center" size="small" content={t("Profile.LearningPath.mandatoryCompleted")} styles={{ width: "65%" }} />
      </Flex>
      <Flex hAlign="end" vAlign="center" styles={{ width: "100%" }}>
        <Flex column hAlign="center" styles={{ width: "35%" }}>
          <Text size="larger" weight="bold" content={learningPathInfo.completedTrainings} />
          <Divider color="black" important styles={{ width: "50%" }} />
          <Text size="larger" weight="bold" content={learningPathInfo.trainings} />
        </Flex>
        <Text align="center" size="small" content={t("Profile.LearningPath.allCompleted")} styles={{ width: "65%" }} />
      </Flex>
      {customTrainings?.length ? (
        <Flex hAlign="end" vAlign="center" styles={{ width: "100%" }}>
          <Flex column hAlign="center" styles={{ width: "35%" }}>
            <Text size="larger" weight="bold" content={customCompleted?.length} />
            <Divider color="black" important styles={{ width: "50%" }} />
            <Text size="larger" weight="bold" content={customTrainings?.length} />
          </Flex>
          <Text align="center" size="small" content={t("Profile.LearningPath.customCompleted")} styles={{ width: "65%" }} />
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
}

export default TrainingsStats;
