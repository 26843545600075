import React, { useEffect, useState } from "react";
import { useAlertData } from "../../utils/context/alertContext";
import { Alert } from "@fluentui/react-northstar";
import { IAlert } from "../../utils/types/alert";

function NotificationAlert() {
  const { alerts, removeAlert } = useAlertData();
  const [displayAlerts, setDisplayAlerts] = useState<IAlert[]>([]);
  useEffect(() => {
    if (alerts.length > 0) {
      setDisplayAlerts(alerts);

      // remove the alert after 3 seconds
      const timer = setTimeout(() => {
        if (displayAlerts.length > 0) {
          removeAlert(displayAlerts[0].message);
          setDisplayAlerts([]);
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alerts, displayAlerts, removeAlert]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 20,
        left: 20,
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {displayAlerts.map((alert, index) => (
        <Alert
          key={index + alert.message}
          content={alert.message}
          dismissible
          variables={{
            backgroundColor: alert.type === "success" ? "#4caf50" : "#f44336",
            color: "white",
          }}
          style={{ padding: "8px 16px 8px 16px" }}
        />
      ))}
    </div>
  );
}

export default NotificationAlert;
