import { authentication, app } from "@microsoft/teams-js";
import { ProviderState, Providers } from '@microsoft/mgt-react';


export async function getAccessToken(isTeams, scopeOptions) {
    if (isTeams) {
        if (!app.isInitialized()) {
            await app.initialize();
        }
        return await authentication.getAuthToken()
    }
    else {
        return await Providers.globalProvider.getAccessToken(scopeOptions);
    }
}


export async function getServerSideToken(scopeOptions) {
    if (!app.isInitialized()) {
        await app.initialize();
    }
    const clientSideToken = await authentication.getAuthToken();
    try {
        const response = await fetch('/token', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer: ' + clientSideToken
            },
            body: JSON.stringify({
                'scopes': scopeOptions ? scopeOptions : ["User.Read", "openid", "offline_access", "email", "profile", "User.ReadBasic.All", "GroupMember.Read.All"],
            }),
            mode: 'cors',
            cache: 'default'
        })
        const responseJson = await response.json();
        if (responseJson.error) {
            throw new Error(responseJson.error);
        } else {
            const token = responseJson.access_token;
            return token
        }
    }
    catch (error) {
        throw new Error(error.message)
    }
}


export function requestConsent() {
    return new Promise((resolve, reject) => {
        authentication.authenticate({
            url: window.location.origin + "/auth-start",
            width: 600,
            height: 535
        })
            .then((result) => {
                resolve(result);
            }).catch((reason) => {
                reject(JSON.stringify(reason));
            });
    })
}


export async function customLogin() {
    try {
        await getServerSideToken()
        Providers.globalProvider.setState(ProviderState.SignedIn)
    } catch (error) {
        if (error.message === "consent_required") {
            try {
                await requestConsent();
                Providers.globalProvider.setState(ProviderState.SignedIn)
            } catch (error) {
                console.error("Failed to open consent popup")
            }
        }
        else {
            console.error("Authentication error")
        }
    }
}