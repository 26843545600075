import React from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { TonePickerProps, DropdownValue } from "../../utils/types/common";

export default function TonePicker({ defaultSelected, disabled, inverted, fluid, handleToneChange }: TonePickerProps) {
  const { t } = useTranslation(["translation"]);

  const dropDownValues: DropdownValue[] = [
    { key: "familiar", header: t("Tones.familiar"), selected: defaultSelected === "familiar", active: defaultSelected === "familiar" },
    { key: "official", header: t("Tones.official"), selected: defaultSelected === "official", active: defaultSelected === "official" },
    { key: "none", header: t("Tones.none"), selected: defaultSelected === "none", active: defaultSelected === "none" },
  ];

  const defaultValue = dropDownValues.find((toneArray) => toneArray.key === defaultSelected);

  function pickTone(item: DropdownValue) {
    if (handleToneChange && item) {
      handleToneChange(item.key);
    }
    return "Tone picked";
  }

  return (
    <Dropdown
      inverted={inverted}
      fluid={fluid}
      styles={{ width: "100%" }}
      disabled={disabled}
      checkable
      items={dropDownValues}
      value={defaultValue}
      defaultValue={defaultValue}
      placeholder={t("Tones.none") ?? ""}
      getA11ySelectionMessage={{
        onAdd: (item) => pickTone(item as DropdownValue),
      }}
    />
  );
}
