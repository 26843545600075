const getTrainingScenarioType = (scenarioType: string | undefined) => {
  switch (scenarioType) {
    case "quiz":
      return "Quiz";
    case "customSkillTest":
      return "Skilltest";
    default:
      return "Training";
  }
};
export default getTrainingScenarioType;
