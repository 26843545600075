const hasMatchingArrayContent = (arrayA: string[] | undefined, arrayB: string[] | undefined) => {
  if (arrayA?.length !== arrayB?.length) {
    return false;
  }
  // Convert both arrays to sets to ignore order and duplicates
  const setA = new Set(arrayA);
  const setB = new Set(arrayB);

  // Check that every string in setA exists in setB
  return [...setA].every((value) => setB.has(value)) && [...setB].every((value) => setA.has(value));
};

export default hasMatchingArrayContent;
