import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";

export const ProgressBar = ({
  completionRate,
  progressBarRef,
  noMandatoryTrainings,
}: {
  completionRate: number;
  progressBarRef: React.Ref<HTMLDivElement>;
  noMandatoryTrainings: boolean;
}) => (
  <Flex gap="gap.small" vAlign="center" styles={{ width: "100%", height: "10%" }}>
    <div id="trainingProgressBarContainer" style={{ width: "95%" }}>
      <div id="trainingProgressBar">
        <div
          ref={progressBarRef}
          id="trainingProgressBarBackground"
          style={{ clipPath: "inset(0% " + (100 - completionRate) + "% 0% 0%)" }}
          key={completionRate}
        />
      </div>
    </div>
    {!noMandatoryTrainings && <Text weight="bold" align="start" size="larger" content={completionRate + " %"} styles={{ width: "30%" }} />}
  </Flex>
);
