import { LearningInfo } from "../types/common";

const getAllTrainings = (learningInfo: LearningInfo | null | undefined) => {
  const ccTrainings =
    learningInfo?.modules
      ?.flatMap((module) => module.moduleSet)
      ?.filter((training, index, array) => index === array.findIndex((t) => t.dialog === training.dialog)) ?? [];
  const customTrainings = learningInfo?.customContent ?? [];

  return { ccTrainings, customTrainings };
};

export default getAllTrainings;
