import { Providers } from "@microsoft/mgt-react";
import { CLIENT_TEAMS, CLIENT_MICROSOFT_WEB, CLIENT_SLACK } from "./authHelpers";

export async function getUserGroups(token, clientInfo) {
  const client = clientInfo.client;

  //we have the microsoft provider. Get from graph.
  if ((client === CLIENT_MICROSOFT_WEB || client === CLIENT_TEAMS) && Providers.globalProvider) {
    const userGroups = await Providers.globalProvider.graph.client.api("me/memberOf").select("id").get();
    return userGroups.value;
  }
  //this is Slack. Get from backend that gets data from slack API
  else if (client === CLIENT_SLACK) {
    const userGroupData = await fetch("slack/userGroups", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
        slackAppId: process.env.REACT_APP_SLACK_CLIENT_ID,
      },
    });
    const userGroups = await userGroupData.json();
    return userGroups.userGroups;
  }
  //no such concept as usergroups from provider
  else {
    return [];
  }
}

export async function getUserGroupNames(token, clientInfo) {
  const client = clientInfo.client;

  //we have the microsoft provider. Get from graph.
  if ((client === CLIENT_MICROSOFT_WEB || client === CLIENT_TEAMS) && Providers.globalProvider) {
    const userGroupNames = await Providers.globalProvider.graph.client.api("groups").select("id,displayName").get();
    return userGroupNames.value;
  }
  //this is Slack. Get from backend that gets data from slack API
  else if (client === CLIENT_SLACK) {
    const userGroupData = await fetch("slack/userGroups/names", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
    const userGroupNames = await userGroupData.json();
    return userGroupNames.userGroups;
  }
  //no such concept as usergroups from provider
  else {
    return [];
  }
}
