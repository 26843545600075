
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";



const reactPlugin = new ReactPlugin()


const browserHistory = createBrowserHistory({ basename: '' });
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY || "InstrumentationKey=N/A",
        extensions: [reactPlugin],

        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();


export { reactPlugin, appInsights };


