import { AvailableLearningPath } from "../types/common";

function getGroupInfo(groupId: string, cyberCoachGroups: AvailableLearningPath[] | undefined) {
  if (!cyberCoachGroups) return;
  const foundGroup = cyberCoachGroups.find((group) => group.groupId === groupId);
  if (foundGroup) {
    return {
      groupId: groupId,
      groupName: foundGroup.groupName,
      groupDesc: foundGroup.groupDesc,
    };
  }
  return undefined;
}

export default getGroupInfo;
