import React, { useContext, useState, createContext } from "react";
import { IAlert, IAlertContextType } from "../types/alert";

export const AlertContext = createContext<IAlertContextType | undefined>(undefined);

export const useAlertData = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};

const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const updateSuccess = (message: string) => {
    setAlerts((prev) => [...prev, { type: "success", message }]);
  };

  const updateError = (message: string) => {
    setAlerts((prev) => [...prev, { type: "error", message }]);
  };
  const removeAlert = (message: string) => {
    setAlerts((prev) => prev.filter((alert) => alert.message !== message));
  };
  return <AlertContext.Provider value={{ alerts, updateSuccess, updateError, removeAlert }}>{children}</AlertContext.Provider>;
};

export default AlertProvider;
