export async function fetchFromBackend(accessToken, endpoint, method, body) {
  try {
    const response = await fetch("api/" + endpoint, {
      headers: {
        authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      method: method ? method : "GET",
      body: body ? JSON.stringify(body) : null,
    });
    if (response.status === 204) {
      return [null, response.status];
    } else {
      const backupRequest = response.clone();
      try {
        const responseData = await response.json();
        return [responseData, response.status];
      } catch {
        //some endpoints returns just a value. Try with that before throwing errors
        const responseData = await backupRequest.text();
        return [responseData, response.status];
      }
    }
  } catch (err) {
    console.log("Backend call to api/" + endpoint + " failed. " + err);
    return [false, null];
  }
}
