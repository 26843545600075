import { useMemo, useState } from "react";
import { LearningInfo, LearningPathNumbers, TrainingModule } from "../types/common";

const useGetLearningPathNumbers = (learningInfo: LearningInfo | undefined | null, mandatoryOnly: boolean) => {
  const [learningPathNumbers, setLearningPathNumbers] = useState<LearningPathNumbers>();
  useMemo(() => {
    if (learningInfo?.modules) {
      const { trainings, completedTrainings, mandatoryTrainings, mandatoryCompleted } = learningInfo.modules.reduce(
        (acc: LearningPathNumbers, module: TrainingModule) => {
          const { trainingAmount, trainingsCompleted, mandatoryTrainingAmount, mandatoryTrainingCompleted } = module.moduleStatistics;

          return {
            trainings: acc.trainings + trainingAmount,
            completedTrainings: acc.completedTrainings + trainingsCompleted,
            mandatoryTrainings: acc.mandatoryTrainings + mandatoryTrainingAmount,
            mandatoryCompleted: acc.mandatoryCompleted + mandatoryTrainingCompleted,
          };
        },
        {
          trainings: 0,
          completedTrainings: 0,
          mandatoryTrainings: 0,
          mandatoryCompleted: 0,
        }
      );

      let completionRate = Math.round((completedTrainings / trainings) * 100);
      if (mandatoryOnly) {
        completionRate = Math.round((mandatoryCompleted / mandatoryTrainings) * 100);
      }
      setLearningPathNumbers({
        trainings,
        mandatoryTrainings,
        completedTrainings,
        mandatoryCompleted,
        completionRate,
      });
    } else {
      setLearningPathNumbers(undefined);
    }
  }, [learningInfo, mandatoryOnly]);
  return learningPathNumbers;
};

export default useGetLearningPathNumbers;
