export function getSlackToken(temptoken) {
    return new Promise((resolve, reject) => {
        fetch('/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'temptoken': temptoken
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json();
                }
            })
            .then((responseJson) => {
                if (responseJson.error) {
                    //we don't have a token need to send user through login flow
                    window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);
                    reject(responseJson.error);
                } else {
                    //hide one-time slack auth code
                    window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);
                    const token = responseJson;
                    resolve(token);
                }
            });
    });
}

//get user information from slack
export async function getSlackuserInfo(token) {
    const slackUserData = await fetch("/slack/userInfo", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        }
    });
    let slackUserJson = await slackUserData.json();
    const userJson = { displayName: slackUserJson.name, userPicture: slackUserJson.picture, tid: slackUserJson["https://slack.com/team_id"], id: slackUserJson.sub, teamName: slackUserJson["https://slack.com/team_name"] }
    return userJson;
}

export async function checkIfSlackAdmin(token) {
    const adminData = await fetch("slack/isAdmin", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token
        }
    });
    const isAdmin = await adminData.json();
    return isAdmin.isAdmin;
}