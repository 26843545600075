import { TrainingType } from "../types/common";
import { ICombinedTraining } from "../types/profile";

const getAllTrainingDetails = (training: ICombinedTraining) => {
  const trainingName = training?.quizName ? training.quizName : training.name ?? "";
  const trainingDialog = training?.quizId ? training?.quizId.toString() : training?.dialog ?? "";
  const imageUrl = training?.dialog ? `${process.env.REACT_APP_IMAGE_BASE_URL}/badge/${training?.dialog}.svg` : "/img/custom_badge.svg" ?? "";
  const trainingType = training?.quizId ? TrainingType.CUSTOM : TrainingType.TRAINING;
  return { trainingName, trainingDialog, imageUrl, trainingType };
};

export default getAllTrainingDetails;
